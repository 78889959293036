<template>
  <div>
    <v-card style="padding: 10px">
      <v-form ref="first_form" v-model="form_one">
        <div class="row">
          <div class="col-md-6 col-12">
            <label class="text-center text-uppercase text--primary bold">{{
              translate("Expense name")
            }}</label>

            <v-select
              style="min-width: 150px"
              filled
              outlined
              :label="translate('Expense')"
              dense
              item-text="name"
              item-value="id"
              v-model="expense_type_id"
              :items="expense_types"
            ></v-select>
          </div>
          <div class="col-md-6 col-12">
            <label class="text-center text-uppercase text--primary bold">{{
              translate("Expense note")
            }}</label>

            <v-text-field
              label="Expense note"
              v-model="note"
              outlined
              dense
              type="text"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12">
            <label class="text-center text-uppercase text--primary bold">{{
              translate("Date")
            }}</label>

            <div
              class="v-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 0px"><span class="notranslate">​</span></legend>
                  </fieldset>

                  <v-datetime-picker class="outlined" v-model="date" @input="menu2">
                  </v-datetime-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label class="text-center text-uppercase text--primary bold">{{
              translate("Amount")
            }}</label>

            <v-text-field
              required
              label="Amount"
              v-model="amount"
              :suffix="currency"
              outlined
              dense
              :rules="emptyRule"
              type="text"
            ></v-text-field>
          </div>
        </div>
      </v-form>
      <v-col class="text-right">
        <v-btn color="primary" v-on:click="submit"> {{ translate("Submit") }} </v-btn>
        <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined>
          {{ translate("Cancel") }}
        </v-btn>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import axios from "axios";
import { mdiPlus, mdiCloseCircleOutline } from "@mdi/js";
import { getExpenseTypes } from "@/ApiManager";
import {
  getCurrency,
  getMeasurements,
  getProducts,
  newExpense,
  newProduct,
} from "@/ApiManager";

export default {
  computed: {},
  created() {
    getCurrency().then((response) => {
      this.currency = response["currency"];
    });
    getExpenseTypes().then((response) => {
      this.expense_types = response;
    });
  },
  methods: {
    cancel: function (_) {
      this.$router.go(-1);
    },
    submit: async function (_) {
      this.$refs.first_form.validate();

      if (this.$refs.first_form.validate() == false) {
        return;
      }
      ///////////////////////////////////
      var date = `${this.date.getFullYear()}/${("0" + (this.date.getMonth() + 1)).slice(
        -2
      )}/${("0" + this.date.getDate()).slice(
        -2
      )} ${this.date.getHours()}:${this.date.getMinutes()}:${this.date.getSeconds()}`;

      this.$store.state.loading = true;
      await newExpense(this.expense_type_id, this.note, date, this.amount);
      this.$router.push({ name: "report_expenses" });
    },
  },
  data() {
    return {
      expense_types: [],
      expense_name: "",
      note: "",
      form_one: null,
      date: new Date(),
      menu2: false,
      currency: "",
      amount: "",
      emptyRule: [(v) => !!v || "Can't be empty!"],
    };
  },
};
</script>
<style scoped>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
.component-container {
  display: table;
  @media (pointer: none), (pointer: coarse) {
    width: calc(100% - (50%));
  }
}
.component-row {
  display: table-row;
}
.component-row div {
  position: relative;
  display: table-cell;
}
.component-row div:nth-child(2) {
  left: 20px;
}
.component-row div:nth-child(3) {
  left: 40px;
}
</style>
